import { inject } from "@angular/core";
import { CanMatchFn } from "@angular/router";
import { TRoleName } from "@app/core/access-control";
import { RoamAclService } from "@app/core/access-control/+data-access";

/**
 * Creates a guard function that checks if the current user's role matches the specified role.
 * @param {TRoleName | TRoleName[]} roles - A role or an array of roles to match against the current user's role.
 * @returns {CanMatchFn} A function that returns an observable of boolean indicating if the role matches.
 */
export const canMatchRole = (roles: TRoleName | TRoleName[]): CanMatchFn => {
  return () => {
    return inject(RoamAclService).isGranted(roles);
  };
};
