import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalSearchRoutingModule } from './global-search-routing.module';
import { GlobalSearchComponent } from './global-search.component';
import { GlobalSearchModuleComponent } from './global-search-module';
import { GlobalSearchCategoryComponent } from './global-search-category';
import { GlobalSearchCategoriesComponent } from './global-search-categories';
import { GlobalSearchEntityComponent } from './global-search-entity';
import { SharedModule } from "@app/shared/shared.module";
import { FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { UnitTableComponent } from "@app/pages/associations/components/associations-detail/units/unit-table/unit-table.component";


@NgModule({
  declarations: [
    GlobalSearchComponent,
    GlobalSearchModuleComponent,
    GlobalSearchCategoryComponent,
    GlobalSearchCategoriesComponent,
    GlobalSearchEntityComponent
  ],
  imports: [
    CommonModule,
    GlobalSearchRoutingModule,
    SharedModule,
    FormsModule,
    RouterModule,
    UnitTableComponent
  ],
  exports: [
    GlobalSearchComponent,
    GlobalSearchModuleComponent,
    GlobalSearchCategoryComponent,
    GlobalSearchCategoriesComponent,
    GlobalSearchEntityComponent
  ]
})
export class GlobalSearchModule { }
