<div class="global-search-entity">
  <p class="entity-title" (click)="entityChange.emit()">
    <mat-icon color="#949494">chevron_left</mat-icon>

    <span>Quick Search by {{ entity.key | titlecase }}</span>
  </p>

  <ng-container [ngSwitch]="entity.key">
    <ng-container *ngSwitchCase="'account'">
      <div class="my-24px">
        <app-roam-input [name]="'Account'"></app-roam-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'address'">
      <div class="my-24px">
        <app-roam-input [name]="'House ID'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Address'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Lot'"></app-roam-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'person'">
      <div class="my-24px">
        <app-roam-input [name]="'Person ID'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'First Name'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Last Name'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Mail Address'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Phone'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Email'"></app-roam-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'vehicle'">
      <div class="my-24px">
        <app-roam-input [name]="'Permit No'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Year'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Make'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Model'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Color'"></app-roam-input>
      </div>

      <div class="my-24px">
        <app-roam-input [name]="'Tag'"></app-roam-input>
      </div>
    </ng-container>

    <mat-slide-toggle>Include Previously Managed</mat-slide-toggle>

    <div class="d-flex">
      <button
        mat-button
        class="continue-btn"
        style="border-radius: 12px; padding: 4px 12px !important"
      >
        Search
      </button>
    </div>
  </ng-container>
</div>
