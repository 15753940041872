import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-global-search-entity",
  templateUrl: "./global-search-entity.component.html",
  styleUrls: ["./global-search-entity.component.scss"],
})
export class GlobalSearchEntityComponent {
  @Input()
  public entity!: any;

  @Output()
  public entityChange: EventEmitter<any>;

  constructor() {
    this.entityChange = new EventEmitter();
  }
}
