import { inject } from "@angular/core";
import { CanMatchFn } from "@angular/router";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { filter, map, take } from "rxjs";

/**
 * Creates a guard function that checks if the current user's module configuration has been loaded;
 */
export const canMatchUserConfigHasLoaded: CanMatchFn = () => {
  const config = inject(UserConfigStore);
  return config.asyncState$.pipe(
    map(x => x === "loaded"),
    filter(Boolean),
    take(1)
  );
};
