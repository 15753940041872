import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UserActionMenuComponent } from "@app/pages/main/user-action-menu/user-action-menu.component";
import { MaterialModule } from "@app/shared/material/material.module";
import { SharedModule } from "@app/shared/shared.module";
import { HeaderComponent } from "./components/header/header.component";
import { MobileMenuComponent } from "./components/mobile-menu/mobile-menu.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { GlobalSearchModule } from "@app/core/components/global-search/global-search.module";
import { MenuBackNavigationComponent } from "@app/pages/main/shared/components/menu-back-navigation/menu-back-navigation.component";
import { ShortcutMenuModule } from "@app/shared/components/shortcut-menu/shortcut-menu.module";

@NgModule({
  declarations: [
    HeaderComponent,
    MobileMenuComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    UserActionMenuComponent,
    GlobalSearchModule,
    MenuBackNavigationComponent,
    ShortcutMenuModule
  ],
  exports: [
    HeaderComponent,
    MobileMenuComponent,
    SidebarComponent,
    ShortcutMenuModule
  ],
})
export class CoreModule {}
