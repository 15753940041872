<div class="global-search-category">
  <p class="category-title" (click)="categoryChange.emit()">
    <mat-icon color="#949494">chevron_left</mat-icon>

    <span>Quick Search by {{ category.name }} Category</span>
  </p>

  <div class="entity-list">
    <div
      class="entity-group"
      *ngFor="let entity of entities"
      (click)="entityChange.emit(entity)"
    >
      <img class="entity-icon" [src]="entity.icon" alt="entity"/>

      <span class="entity-label">{{ entity.label }}</span>
    </div>
  </div>
</div>
