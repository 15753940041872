import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { IModule } from '@app/shared/interfaces';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  @Input() mobileMenu!: MatDrawer;
  @Input() modules: IModule[] = [];

  isMobileMenuOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
