import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IModule } from '@shared/interfaces';

@Component({
  selector: "app-global-search-categories",
  templateUrl: "./global-search-categories.component.html",
  styleUrls: ["./global-search-categories.component.scss"],
})
export class GlobalSearchCategoriesComponent {
  @Input()
  public categories: IModule[] = [];

  @Output()
  public selectCategoryChange: EventEmitter<IModule>;

  constructor() {
    this.selectCategoryChange = new EventEmitter();
  }
}
