<div
  class="sidebar__container h-100 d-flex"
  [class.collapse--menu]="navigationService.shortMenuVisible$ | async">
  <div class="sidebar__wrapper" [class.collapsed]="isSecondMenuOpen">
    <!-- [@widthGrow]="isSecondMenuOpen ? 'open' : 'closed'"> -->
    <button mat-button routerLink="overview" class="roam-icon">
      <!-- <span
        class="logo-show-desktop"
        [inlineSVG]="
          isSecondMenuOpen ?
            '/assets/svg/sidenav/roam-mobile-logo.svg'
          : '/assets/svg/home-page/roam-icon-light.svg'
        "></span> -->
      <div class="logo-show-desktop logo-place">
        <img
          [ngStyle]="{
            'background-color': informationStore.state().entities.bgColor
          }"
          [src]="informationStore.state().entities.logoUrl"
          alt="" />
        <span
          *ngIf="!isSecondMenuOpen"
          class="logo-place-name fw-500"
          [ngStyle]="{
            'font-size': informationStore.state().entities.fontSize + 'px'
          }">
          {{ informationStore.state().entities.name }}
        </span>
      </div>

      <span
        class="logo-show"
        [inlineSVG]="'/assets/svg/sidenav/roam-mobile-logo.svg'"></span>
    </button>

    <button
      mat-icon-button
      class="create-new justify-center"
      [class.full]="!isSecondMenuOpen"
      (click)="openShortcutMenuHandler()"
      *ngIf="showShortcutMenu">
      <mat-icon>
        <img [src]="icon.plusTrWhite.src" [alt]="icon.plusTrWhite.alt" />
      </mat-icon>
      <span class="menu-name" [class.hide]="isSecondMenuOpen">Create New</span>
    </button>

    <div
      class="sidebar__menu w-100"
      [class.pt-0]="!showShortcutMenu"
      *ngIf="modules.length; else loader">
      <ng-container
        *ngTemplateOutlet="
          menu;
          context: { $implicit: modules, showTitle: false }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          menu;
          context: { $implicit: additionalModules, showTitle: false }
        "></ng-container>

      <!-- USER PROFILE -->
      <div class="user-action" [class.close]="isSecondMenuOpen">
        @if (isAdminUser) {
          <ng-container
            *ngTemplateOutlet="
              menu;
              context: { $implicit: modulesBottom, showTitle: false }
            "></ng-container>
        }

        <app-user-action-menu></app-user-action-menu>
      </div>
    </div>
  </div>

  <!-- SECONDARY MENU -->
  <div class="sidebar__second-menu" [class.pt-118px]="!showShortcutMenu">
    <div *ngIf="isSecondMenuOpen">
      <ng-container
        *ngIf="
          currentPage === 'recent' || currentPage === 'favorites';
          else defaultActions
        ">
        <ng-container
          *ngTemplateOutlet="
            menu;
            context: { $implicit: subMenus[currentPage], showTitle: true }
          "></ng-container>
      </ng-container>

      <ng-template #defaultActions>
        <div class="mb-12">
          <menu-back-navigation
            [pageName]="pageName"
            (onClick)="isSecondMenuOpen = false" />
        </div>

        <ng-container *ngIf="currentPage === 'associations'">
          <mat-form-field
            class="label-field w-100 input-transparent pl-0"
            floatLabel="auto"
            style="padding: 0 16px">
            <input
              matInput
              (input)="searchAssociations()"
              [(ngModel)]="searchValue"
              placeholder="Search Name"
              style="font-size: 14px" />
            <mat-icon *ngIf="!searchValue" matSuffix> search </mat-icon>
            <mat-icon
              *ngIf="searchValue"
              matSuffix
              (click)="searchValue = ''; searchAssociations()">
              close
            </mat-icon>
          </mat-form-field>
        </ng-container>

        <div class="sub-menu-nested" *ngIf="currentPage === 'financials'">
          <ng-container *ngFor="let sub of subMenuFinance.categories">
            <h6 class="sub-menu-category">
              {{ sub?.name }}
            </h6>

            <ng-container *ngFor="let item of sub.menus">
              <a
                *ngIf="item.type && item.slug"
                mat-menu-item
                [routerLink]="[currentPage, item.type, item.slug]"
                routerLinkActive="active">
                <p class="details-description">
                  {{ item.title | translate }}
                </p>
              </a>
              <a
                *ngIf="item.type && !item.slug"
                mat-menu-item
                [routerLink]="[currentPage, item.type]"
                routerLinkActive="active">
                <p class="details-description">{{ item.title | translate }}</p>
              </a>
              <a
                *ngIf="!item.type"
                mat-menu-item
                [routerLink]="[currentPage]"
                routerLinkActive="active">
                <p class="details-description">{{ item.title | translate }}</p>
              </a>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="currentPage !== 'financials'">
          <ng-container *ngFor="let item of subMenus[currentPage]">
            <a
              *ngIf="item.type && item.slug"
              mat-menu-item
              [routerLink]="[currentPage, item.type, item.slug]"
              routerLinkActive="active">
              <p class="details-description">
                {{ item.title | translate }}
              </p>
            </a>
            <a
              *ngIf="item.type && !item.slug"
              mat-menu-item
              [routerLink]="[currentPage, item.type]"
              routerLinkActive="active">
              <p class="details-description">{{ item.title | translate }}</p>
            </a>
            <a
              *ngIf="!item.type"
              mat-menu-item
              [routerLink]="[currentPage]"
              routerLinkActive="active">
              <p class="details-description">{{ item.title | translate }}</p>
            </a>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <ng-template #menu let-modules let-showTitle="showTitle">
    <div class="long-menu">
      <button
        mat-icon-button
        *ngFor="let module of modules"
        (click)="onNavigate(module.slug, module.name)"
        [ngClass]="{
          active: currentPage === module.slug,
          'short-menu':
            isSecondMenuOpen || (navigationService.shortMenuVisible$ | async),
          'long-menu': showTitle
        }"
        [matTooltip]="module.name"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isSecondMenuOpen || showTitle">
        <mat-icon>
          <span class="item-icon" [inlineSVG]="getIconPath(module.slug)"></span>
        </mat-icon>
        <span
          class="details-description"
          [class.set-text]="!isSecondMenuOpen || showTitle">
          {{ module.name }}
        </span>
      </button>
    </div>

    <!-- MOBILE MENU VIEW -->

    <div class="mobile-short-menu short-menu">
      <button
        mat-icon-button
        *ngFor="let module of modules"
        (click)="onNavigate(module.slug, module.name)"
        [ngClass]="{
          'active short-menu menu-available': currentPage === module.slug,
          'no-menu': !subMenus[hoverPage]
        }"
        appSidebarFloatingMenu
        [attr.data-slug]="module.slug"
        (mouseover)="hoverPage = module.slug"
        (touchstart)="hoverPage = module.slug"
        [title]="module.name"
        [parentPath]="hoverPage"
        [menus]="subMenus[hoverPage]"
        [slug]="module.slug"
        (onSearch)="filterByName($event)">
        <mat-icon>
          <span
            class="item-icon c-pointer"
            [inlineSVG]="getIconPath(module.slug)"></span>
        </mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #loader>
    <ul class="menu-loader">
      <li *ngFor="let loader of [0]">
        <app-loader-dot [light]="true" [size]="3"></app-loader-dot>
      </li>
    </ul>
  </ng-template>
</div>
