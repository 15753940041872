<ng-container *ngIf="user && !hideBar">
  <mat-toolbar class="navbar header justify-between">
    <app-global-search class="w-100" [categories]="modules()" />

    <div class="align-center gap-10">
      <mat-form-field appearance="outline" class="roam-field">
        <mat-select
          class="roam-menu"
          placeholder="View As"
          panelClass="association-select radio-type reverse"
          (selectionChange)="selectManager($event)"
          [value]="manager()?.id">
          <mat-select-trigger class="align-center justify-between">
            {{ manager()?.name }}
            <span *ngIf="manager()" (click)="userConfig.selectManager(null)">
              <span
                class="svg-16 d-flex pl-5"
                [inlineSVG]="icon.closeBlack.src"></span>
            </span>
          </mat-select-trigger>
          <div class="search-wrapper">
            <input
              type="search"
              placeholder="Search"
              [formControl]="searchManagerForm" />
            <mat-icon>search</mat-icon>
          </div>
          @for (man of filteredManagers$ | async; track man.id) {
            <mat-option [value]="man.id">
              <p class="roam-text">{{ man.name }}</p>
            </mat-option>
          }
        </mat-select>
        <button matSuffix mat-icon-button type="button">
          <mat-icon class="arrow-down">expand_more</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="roam-field">
        <mat-select
          class="roam-menu"
          placeholder="Select Association"
          panelClass="association-select radio-type reverse"
          (selectionChange)="selectAssociation($event)"
          [value]="association()?.id || 'all'">
          <mat-select-trigger
            #associationTrigger
            class="align-center justify-between">
            {{ association()?.name || "All associations" }}
            <span
              *ngIf="association()"
              (click)="userConfig.selectAssociation('all')">
              <span
                class="svg-16 d-flex pl-5"
                [inlineSVG]="icon.closeBlack.src"></span>
            </span>
          </mat-select-trigger>
          <div class="search-wrapper">
            <input
              type="search"
              placeholder="Search"
              [formControl]="searchAssociationForm" />
            <mat-icon>search</mat-icon>
          </div>
          <!-- TODO: restore loader later! -->
          <!-- <mat-option class="mat-loading" *ngIf="loader.association"> -->
          <!--   <div class="align-center font-14px"> -->
          <!--     Loading <app-loader-dot [size]="3"></app-loader-dot> -->
          <!--   </div> -->
          <!-- </mat-option> -->
          <mat-option value="all">
            <p class="roam-text">Select all</p>
          </mat-option>
          @for (ass of filteredAssociations$ | async; track ass.id) {
            <mat-option [value]="ass.id">
              <p class="roam-text">{{ ass.name }}</p>
            </mat-option>
          }
        </mat-select>
        <button matSuffix mat-icon-button type="button">
          <mat-icon class="arrow-down">expand_more</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-toolbar>
</ng-container>
