import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IModule } from "../../../../shared/interfaces";

@Component({
  selector: "app-global-search-category",
  templateUrl: "./global-search-category.component.html",
  styleUrls: ["./global-search-category.component.scss"],
})
export class GlobalSearchCategoryComponent {
  @Input()
  public category!: IModule;

  @Output()
  public categoryChange: EventEmitter<IModule>;

  @Output()
  public entityChange: EventEmitter<any>;

  public entities = [
    {
      icon: "/assets/svg/filter/ic-profile.svg",
      label: "By Account",
      key: "account",
    },
    {
      icon: "/assets/svg/filter/ic-location.svg",
      label: "By Address",
      key: "address",
    },
    {
      icon: "/assets/svg/filter/ic-contact.svg",
      label: "By Person",
      key: "person",
    },
    {
      icon: "/assets/svg/filter/ic-car.svg",
      label: "By Vehicle",
      key: "vehicle",
    },
  ];

  constructor() {
    this.categoryChange = new EventEmitter();
    this.entityChange = new EventEmitter();
  }
}
