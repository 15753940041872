export interface ISearchHistory {
  name: string;
  timestamp: number;
}

export class SearchHistory {
  public name!: string;
  public timestamp!: number;
  public data!: any;

  constructor(name: string, timestamp: number, data: any = '') {
    this.name = name;
    this.timestamp = timestamp;
    this.data = data;
  }
}
