import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { searchStage } from "@app/core/const/global-search.const";
import { iconLib } from "@app/core/const/roam-icon";
import { IModule } from "@app/shared/interfaces";
import { IGlobalSearch } from "@app/shared/interfaces/global-search.interface";
import { SearchHistory } from "@app/shared/interfaces/search-history.interface";
import { GlobalSearchService } from "@app/shared/services/global-search.service";
import { APP_ROUTES } from "@app/utils/routes";
import { BehaviorSubject, Subject } from "rxjs";
import {
  distinctUntilChanged,
  finalize,
  take,
  takeUntil,
} from "rxjs/operators";

@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @Input()
  set categories(value: IModule[]) {
    this.categoryType = [
      value[1],
      value[3],
      value[4],
      value[6],
      value[8],
      value[9],
      value[7],
      value[2],
      value[5],
    ];
    this.globalSearch.categoryType$.next(this.categoryType);
  }

  public categoryType!: IModule[];
  public isOpenSuggestion!: boolean;
  public selectedCategory!: IModule | null;
  public selectedEntity!: any;
  public keyword!: string;
  public prevKeyword!: string;
  public result: any[] = [];
  public isLoading!: boolean;
  public stageStatus!: number;
  public isNextStageAvailable: boolean = true;
  public callLevelStage$: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  public icon = iconLib;
  public isLoadingType: boolean = false;
  private cancelServiceRequests$ = new Subject<void>();
  private onDestroy$ = new Subject<void>();

  router = inject(Router);
  route = inject(ActivatedRoute);

  @ViewChild("keywordInput") keywordInput!: ElementRef<HTMLInputElement>;

  constructor(
    private activatedRoute: ActivatedRoute,
    public globalSearch: GlobalSearchService
  ) {}

  ngOnInit(): void {
    this.globalSearch.getSearchHistory();
    this.route.queryParamMap.pipe(takeUntil(this.onDestroy$)).subscribe(qp => {
      const keyword = qp.get("keyword")?.trim();
      if (keyword && this.router.url.startsWith("/" + APP_ROUTES.SEARCH)) {
        this.keyword = keyword;
        this.globalSearch.keyword$.next(keyword);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.cancelRequest();
    this.cancelServiceRequests$.unsubscribe();
  }

  cancelRequest() {
    this.isLoading = false;
    this.isNextStageAvailable = false;
  }

  trackFn(index: number, user: any): any {
    return user.id;
  }

  public onInputFocus() {}

  public selectCategory(category: IModule) {
    this.selectedCategory = category;
    this.keyword = category ? `in:${this.selectedCategory.slug}` : "";
  }

  public selectEntity(entity: { icon: string; key: string; label: string }) {
    this.selectedEntity = entity;
    this.keyword =
      entity ?
        `${this.keyword}:by ${entity.key}:`
      : `in:${this.selectedCategory?.slug}`;
  }

  public openSuggestion() {
    this.isOpenSuggestion = true;
  }

  public closeSuggestion() {
    this.isOpenSuggestion = false;
    this.selectedCategory = null;
    this.selectedEntity = null;
    this.resetKeyword();
    this.cancelRequest();
    this.cancelServiceRequests$.next();
  }

  public closeSuggestionAfterClick(item: any): void {
    this.keyword = item.name;
    const searchHistory = new SearchHistory(
      this.keyword,
      new Date().getTime(),
      item
    );
    this.globalSearch.setSearchHistory(searchHistory);
    this.closeSuggestion();
  }

  /* TODO | aq | create const for this */
  searchTypesConst = [
    {
      category: "Accounting",
      searchType: "Accounting List",
      subCategory: "Accounting Lists",
    },
    {
      category: "Accounting",
      searchType: "Bill Payment",
      subCategory: "Bill Payments",
    },
    { category: "Accounting", searchType: "Bill", subCategory: "Bills" },
    { category: "Accounting", searchType: "Charge", subCategory: "Charges" },
    { category: "Accounting", searchType: "Check", subCategory: "Checks" },
    {
      category: "Accounting",
      searchType: "Credit Memo",
      subCategory: "Credit Memos",
    },
    { category: "Accounting", searchType: "Invoice", subCategory: "Invoices" },
    {
      category: "Accounting",
      searchType: "Journal Entry",
      subCategory: "Journal Entrys",
    },
    {
      category: "Accounting",
      searchType: "Received Payment",
      subCategory: "Received Payments",
    },
    {
      category: "Accounting",
      searchType: "Vendor Credit",
      subCategory: "Vendor Credits",
    },
    {
      category: "Associations",
      searchType: "Property",
      subCategory: "Association",
    },
    {
      category: "Associations",
      searchType: "Board of Director",
      subCategory: "Board of Directors",
    },
    {
      category: "Associations",
      searchType: "Committee",
      subCategory: "Committees",
    },
    { category: "Associations", searchType: "File", subCategory: "Files" },
    { category: "Associations", searchType: "Unit", subCategory: "Units" },
    {
      category: "Contacts",
      searchType: "User",
      subCategory: "Customer Accounts",
    },
    { category: "Contacts", searchType: "Employee", subCategory: "Employees" },
    {
      category: "Contacts",
      searchType: "Homeowner",
      subCategory: "Homeowners",
    },
    { category: "Contacts", searchType: "Tenant", subCategory: "Tenants" },
    { category: "Contacts", searchType: "Vendor", subCategory: "Vendors" },
    {
      category: "Reporting",
      searchType: "Violation",
      subCategory: "Violations",
    },
  ];

  showMore(resultSearchType: string) {
    const keyword = this.keyword;
    const searchType = this.searchTypesConst.find(
      each => each.searchType === resultSearchType
    );

    if (!searchType) console.warn(resultSearchType + " const not found");
    if (!searchType) return;

    const { category, subCategory } = searchType;

    const queryParams: Params = { category, keyword, subCategory };

    this.router
      .navigate(["search"], {
        queryParams,
        queryParamsHandling: "merge",
      })
      .then(() => {
        this.keywordInput.nativeElement.blur();
      });

    this.closeSuggestion();
  }

  public searchGlobal(): void {
    if (this.keyword !== "") {
      this.cancelServiceRequests$.next();
      const payload: IGlobalSearch = {
        string: this.keyword.trim(),
        stage: searchStage.DEFAULT,
      };
      this.result = [];
      this.isLoading = true;
      this.isNextStageAvailable = true;

      this.globalSearch
        .searchGlobal(payload)
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.onDestroy$),
          finalize(() => this.callLevelService())
        )
        .subscribe({
          next: resp => {
            const category = {
              ...resp.modelResults[0],
              dataDisplay:
                resp.modelResults[0]?.data?.length > 4 ?
                  resp.modelResults[0]?.data.slice(0, 3)
                : resp.modelResults[0]?.data,
            };

            this.result = [...this.result, category];
            this.isLoading = false;
            this.stageStatus = searchStage.DEFAULT;
            this.isNextStageAvailable = resp.nextStageAvailable;
          },
          error: () => {
            this.isLoading = false;
          },
        });
    } else {
      this.result = [];
      this.cancelRequest();
      this.cancelServiceRequests$.next();
    }
  }

  private callLevelService(): void {
    this.stageStatus = this.stageStatus + 1;
    const payload: IGlobalSearch = {
      string: this.keyword,
      stage: this.stageStatus,
    };

    this.globalSearch
      .searchGlobal(payload)
      .pipe(takeUntil(this.cancelServiceRequests$), takeUntil(this.onDestroy$))
      .subscribe({
        next: resp => {
          this.isNextStageAvailable = resp.nextStageAvailable;
          if (resp) {
            const category = {
              ...resp.modelResults[0],
              dataDisplay: resp.modelResults[0]?.data.splice(0, 3),
            };
            this.result = [...this.result, category];
            if (resp.nextStageAvailable) {
              this.callLevelService();
            }
          }
        },
        error: () => {
          this.callLevelService();
        },
      });
  }

  public deleteHistoryList(list: any): void {
    this.globalSearch.deleteHistoryList(list.timestamp);
  }

  inputClear() {
    this.keywordInput.nativeElement.focus();
    setTimeout(() => {
      this.keyword = "";
      this.searchGlobal();
    }, 300);
  }

  openResults(keyword: string = "") {
    const kw = keyword || this.keyword;
    this.isOpenSuggestion = false;
    this.router
      .navigate(["search"], {
        queryParams: {
          keyword: kw,
        },
        queryParamsHandling: "merge",
      })
      .then(() => {
        this.keywordInput.nativeElement.blur();
      });
  }

  resetKeyword() {
    if (!this.keyword) {
      return;
    }
    setTimeout(() => {
      this.globalSearch.keyword$
        .pipe(take(1))
        .subscribe(val => (this.keyword = val));
    }, 200);
  }
}
