<div class="mobile-menu-wrapper h-100">
    <ng-container *ngIf="mobileMenu.opened; else closedMenu">
        <button mat-menu-item (click)="mobileMenu.close()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <ng-container *ngFor="let module of modules">
            <button mat-menu-item [routerLink]="module.slug" routerLinkActive="active" (click)="mobileMenu.close()">
                <img [src]="'/assets/svg/mobile-menu/' + module.slug + '.svg'" alt="overview icon">
                <span class="roam-text">{{ module.name }}</span>
            </button>
        </ng-container>
    </ng-container>
    <ng-template #closedMenu>
        <div class="closed-menu p-relative">
            <mat-icon class="circle-icon" (click)="mobileMenu.open()">chevron_right</mat-icon>
        </div>
    </ng-template>
</div>
