<div class="global-search-categories">
  <p class="categories-title">Quick Search by Category</p>

  <div class="categories">
    <div
      class="category-group"
      *ngFor="let category of categories"
      (click)="selectCategoryChange.emit(category)"
    >
      <img
        class="categories-icon"
        [src]="'/assets/svg/sidenav/' + category.slug + '.svg'"
        alt="category"
      />

      <span class="categories-label">{{ category.name }}</span>
    </div>
  </div>
</div>
