<div class="global-search-module">
  <div class="module-title">
    <p>{{ title | titlecase }}</p>

    <p
      class="module-showmore"
      (click)="onShowMore.emit()"
    >
      Show More
    </p>
  </div>

  <div
    class="module-search"
    *ngFor="let item of data"
    (click)="navigateTo(item)"
  >
    <div class="align-center gap-10">
      <img
        alt="search"
        class="module-search-icon"
        [class.br-none]="!item?.avatarUrl"
        [src]="icon ? icon : item?.avatarUrl || setIcon[title]"
      />

      <p class="module-search-result">
        {{ item.name ?? item.unitName }}
      </p>
    </div>

    <span
      class="svg-14 d-flex c-pointer"
      *ngIf="title === 'recent search'"
      [inlineSVG]="iconClose"
      (click)="deleteList($event, item)"
    ></span>
  </div>
</div>