import { inject } from "@angular/core";
import { CanMatchFn } from "@angular/router";
import { TResourceSlug } from "@app/core/access-control";
import { RoamAclService } from "@app/core/access-control/+data-access";

/**
 * Creates a guard function that checks if the current user's role matches the specified role.
 * @param {TResourceSlug | TResourceSlug[]} resources - A module slug or an array of module slugs to match against the current user's available modules.
 * @returns {CanMatchFn} A function that returns an observable of boolean indicating if the role matches.
 */
export const canMatchHasModuleAccess = (
  resources: TResourceSlug | TResourceSlug[]
): CanMatchFn => {
  return () => {
    return inject(RoamAclService).hasModuleAccess(resources);
  };
};
